<template>
  <div class="container my-24 my-lg-32 user-select-none">
    <v-card class="py-32 px-16 p-xl-32">
      {{ $root.error.message }}
    </v-card>
  </div>
</template>

<script>
import VCard from '@components/base/VCard'
import { DEFAULT_LOGO_KEY } from '@constants/components'

export default {
  name: 'WarningRoute',
  components: { VCard },
  inject: ['global'],
  mounted () {
    this.global.logo.setLogo({
      url: DEFAULT_LOGO_KEY
    })
  }
}
</script>
